import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Container,
  TextField,
  Button,
  Paper,
  Grid,
  Snackbar,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/Share';
import getMPTheme from './theme/getSignInSideTheme';
import { PaletteMode, ThemeProvider, createTheme } from '@mui/material/styles';
import WorkIcon from '@mui/icons-material/Work';
import { Navigate, useNavigate } from 'react-router-dom';
import MessageIcon from '@mui/icons-material/Message';

const API_BASE_URL = 'https://backend-summer-butterfly-3214.fly.dev';

const createAxiosInstance = (token = null) => {
  const config = {
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true
  };
  
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  return axios.create(config);
};

const drawerWidth = 240;
const ALLOWED_ROLES = ['reader', 'editor', 'admin'];

function Folder() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [ownedFolders, setOwnedFolders] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [sharedFolders, setSharedFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [permissionsInput, setPermissionsInput] = useState({});
  const [mode, setMode] = React.useState('dark');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const MPTheme = createTheme(getMPTheme(mode));
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  useEffect(() => {
    fetchOwnedFolders();
    fetchSharedFolders();
  }, []);

  const fetchOwnedFolders = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) return;
      
      const api = createAxiosInstance(accessToken);
      const response = await api.get('/api/folders');
      
      const foldersWithUsernames = await Promise.all(
        response.data.map(async (folder) => {
          const usernamesResponse = await api.get(`/api/folders/${folder._id}/shared_users`);
          return { ...folder, sharedUsernames: usernamesResponse.data };
        })
      );
      setOwnedFolders(foldersWithUsernames);
    } catch (error) {
      console.error('Error fetching folders:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      setSnackbar({ open: true, message: 'Error fetching folders' });
    }
  };

  const createFolder = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) return;
      
      const api = createAxiosInstance(accessToken);
      const response = await api.post('/api/folders', { name: folderName });
      
      console.log('Folder created:', response.data);
      setFolderName('');
      fetchOwnedFolders();
      setSnackbar({ open: true, message: 'Folder created successfully' });
    } catch (error) {
      console.error('Error creating folder:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      setSnackbar({ open: true, message: 'Error creating folder' });
    }
  };

  const deleteFolder = async (folderId) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) return;
      
      const api = createAxiosInstance(accessToken);
      const response = await api.delete(`/api/folders/${folderId}`);
      
      console.log('Folder deleted:', response.data);
      fetchOwnedFolders();
      setSnackbar({ open: true, message: 'Folder deleted successfully' });
    } catch (error) {
      console.error('Error deleting folder:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      setSnackbar({ open: true, message: 'Error deleting folder' });
    }
  };

  const addPermission = async (folderId) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) return;
      
      const api = createAxiosInstance(accessToken);
      const { userToAdd, userRole } = permissionsInput[folderId] || { userToAdd: '', userRole: '' };
      
      const userIdResponse = await api.get(`/api/user/username-to-id/${userToAdd}`);
      const userId = userIdResponse.data.user_id;

      const response = await api.post(`/api/folders/${folderId}/add_permission`, {
        user_id: userId,
        role: userRole,
        folder_id: folderId
      });
      
      console.log('Permission added:', response.data);
      setPermissionsInput((prev) => ({
        ...prev,
        [folderId]: { userToAdd: '', userRole: '' },
      }));
      fetchOwnedFolders();
      setSnackbar({ open: true, message: 'Permission added successfully' });
    } catch (error) {
      console.error('Error adding permission:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      setSnackbar({ open: true, message: 'Error adding permission' });
    }
  };

  const handleInputChange = (folderId, field, value) => {
    setPermissionsInput((prev) => ({
      ...prev,
      [folderId]: {
        ...prev[folderId],
        [field]: value,
      },
    }));
  };

  const fetchSharedFolders = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) return;
      
      const api = createAxiosInstance(accessToken);
      const response = await api.get('/api/folders/shared_with_me');
      
      setSharedFolders(response.data);
    } catch (error) {
      console.error('Error fetching shared folders:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      setSnackbar({ open: true, message: 'Error fetching shared folders' });
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {[
          { text: 'Dashboard', icon: <DashboardIcon />, route: '/dashboard' },
          { text: 'Jobs', icon: <WorkIcon />, route: '/jobs' },
          { text: 'Messages', icon: <MessageIcon />, route: '/messaging' },
          { text: 'Folders', icon: <FolderIcon />, route: '/folders' },
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={() => handleNavigation(item.route)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Folders
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>
                    Create New Folder
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Folder Name"
                      value={folderName}
                      onChange={(e) => setFolderName(e.target.value)}
                      sx={{ mr: 2 }}
                    />
                    <Button variant="contained" onClick={createFolder} startIcon={<AddIcon />}>
                      Create
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>
                    Owned Folders
                  </Typography>
                  <List>
                    {ownedFolders.map((folder) => (
                      <ListItem key={folder._id}>
                        <ListItemIcon>
                          <FolderIcon />
                        </ListItemIcon>
                        <ListItemText 
                          primary={<Link to={`/folder/${folder._id}`}>{folder.name}</Link>}
                          secondary={
                            <Box sx={{ mt: 1 }}>
                              {folder.sharedUsernames.map((username, index) => (
                                <Chip key={index} label={username} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
                              ))}
                            </Box>
                          }
                        />
                        <IconButton onClick={() => deleteFolder(folder._id)}>
                          <DeleteIcon />
                        </IconButton>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: 2 }}>
                          <TextField
                            size="small"
                            placeholder="Username"
                            value={permissionsInput[folder._id]?.userToAdd || ''}
                            onChange={(e) => handleInputChange(folder._id, 'userToAdd', e.target.value)}
                            sx={{ mb: 1 }}
                          />
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Role</FormLabel>
                            <RadioGroup
                              row
                              value={permissionsInput[folder._id]?.userRole || ''}
                              onChange={(e) => handleInputChange(folder._id, 'userRole', e.target.value)}
                            >
                              {ALLOWED_ROLES.map((role) => (
                                <FormControlLabel
                                  key={role}
                                  value={role}
                                  control={<Radio size="small" />}
                                  label={role}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<ShareIcon />}
                            onClick={() => addPermission(folder._id)}
                            sx={{ mt: 1 }}
                          >
                            Share
                          </Button>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>
                    Shared with Me
                  </Typography>
                  <List>
                    {sharedFolders.map((folder) => (
                      <ListItem key={folder._id}>
                        <ListItemIcon>
                          <FolderIcon />
                        </ListItemIcon>
                        <ListItemText 
                          primary={<Link to={`/folder/${folder._id}`}>{folder.name}</Link>}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </ThemeProvider>
  );
}

export default Folder;

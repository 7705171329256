import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const API_BASE_URL = 'https://backend-summer-butterfly-3214.fly.dev';

// API client configuration
const createAxiosInstance = (token = null) => {
  const config = {
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true
  };
  
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  return axios.create(config);
};

function Admin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const api = createAxiosInstance();
      const response = await api.post('/admin/login', { 
        username, 
        password 
      });
      
      const { access_token } = response.data;
      localStorage.clear();
      localStorage.setItem('access_token', access_token);
      setIsLoggedIn(true);
      setError('');
    } catch (error) {
      console.error('Error logging in:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      setError(error.response?.data?.error || 'Invalid username or password');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Admin Login
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
          <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Admin Dashboard</Typography>
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
      <ClientApplications />
      <ActiveDMs/>
    </Container>
  );
}

function ClientApplications() {
  const [applications, setApplications] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientApplications();
  }, []);

  const fetchClientApplications = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const api = createAxiosInstance(accessToken);
      const response = await api.get('/api/client/query_applications');
      
      const appsWithUsernames = await Promise.all(response.data.map(async (app) => {
        const userInfo = await getUserInfo(app.userId);
        const interestedUsers = await Promise.all(
          (app.interested_users || []).map(getUserInfo)
        );
        return { ...app, userInfo, interestedUsers };
      }));
      
      setApplications(appsWithUsernames);
    } catch (error) {
      console.error('Error fetching client applications:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
    }
  };

  const getUserInfo = async (userId) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const api = createAxiosInstance(accessToken);
      const response = await api.get(`/api/user/id-to-username/${userId}`);
      return { id: userId, username: response.data.username };
    } catch (error) {
      console.error('Error fetching user info:', error);
      return { id: userId, username: 'Unknown User' };
    }
  };

  const handleMessageUser = (userId) => {
    navigate(`/dm/${userId}`);
  };

  const handleDeleteApplication = async () => {
    if (!applicationToDelete) return;
  
    try {
      const accessToken = localStorage.getItem('access_token');
      const api = createAxiosInstance(accessToken);
      
      const response = await api.delete(`/api/client/applications/${applicationToDelete}`);
      console.log('Delete response:', response.data);
      
      setDeleteDialogOpen(false);
      setApplicationToDelete(null);
      fetchClientApplications();
    } catch (error) {
      console.error('Error deleting application:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
    }
  };

  return (
    <div className="client-applications">
      <Typography variant="h5" gutterBottom>Client Applications</Typography>
      {applications.map((application) => (
        <Accordion key={application._id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{application.name || `Application ${application._id}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemText
                  primary="Applicant"
                  secondary={
                    <Typography
                      component="span"
                      variant="body2"
                      color="primary"
                      onClick={() => handleMessageUser(application.userInfo.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      {application.userInfo.username}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Interested Users"
                  secondary={
                    application.interestedUsers.map((user, index) => (
                      <Chip
                        key={index}
                        label={user.username}
                        onClick={() => handleMessageUser(user.id)}
                        style={{ margin: '2px', cursor: 'pointer' }}
                      />
                    ))
                  }
                />
              </ListItem>
              {Object.entries(application).map(([key, value]) => {
                if (['_id', 'userId', 'interested_users', 'userInfo', 'interestedUsers'].includes(key)) return null;
                return (
                  <ListItem key={key}>
                    <ListItemText primary={key} secondary={JSON.stringify(value)} />
                  </ListItem>
                );
              })}
            </List>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                color="error"
                onClick={() => {
                  setApplicationToDelete(application._id);
                  setDeleteDialogOpen(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this application?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteApplication} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
function ActiveDMs() {
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    fetchAllConversations();
  }, []);

  const fetchAllConversations = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const api = createAxiosInstance(accessToken);
      const response = await api.get('/api/admin/all_messages');
      setConversations(response.data);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  return (
    <div className="active-dms">
      <Typography variant="h5" gutterBottom>All Active DMs</Typography>
      {conversations.map((conversation, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Conversation between {conversation.sender_username} and {conversation.recipient_username}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {/* Display each message in the conversation */}
              {conversation.messages.map((message) => (
                <ListItem key={message._id}>
                  <ListItemText
                    primary={`From ${message.sender_id === conversation.sender_id ? conversation.sender_username : conversation.recipient_username}`}
                    secondary={message.content}
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default Admin;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
  Container,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MessageIcon from '@mui/icons-material/Message';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getMPTheme from './theme/getSignInSideTheme';
import WorkIcon from '@mui/icons-material/Work';
import FolderIcon from '@mui/icons-material/Folder';
import { Navigate, useNavigate } from 'react-router-dom';

const API_BASE_URL = 'https://backend-summer-butterfly-3214.fly.dev';

const createAxiosInstance = (token = null) => {
  const config = {
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true
  };
  
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  return axios.create(config);
};

const drawerWidth = 240;

function ClientApplication() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [userId, setUserId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [payment, setPayment] = useState('');
  const [duration, setDuration] = useState('');
  const [description, setDescription] = useState('');
  const [questions, setQuestions] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [newFieldName, setNewFieldName] = useState('');
  const [mode, setMode] = React.useState('light');
  const MPTheme = createTheme(getMPTheme(mode));
  const [showCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }

    const fetchUserRole = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          setIsLoading(false);
          return;
        }

        const api = createAxiosInstance(accessToken);
        const response = await api.get('/api/user-info');

        const { role, username, _id } = response.data;
        console.log(username);
        setUserRole(role);
        setUserId(_id);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user role:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
        setIsLoading(false);
      }
    };

    fetchUserRole();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        console.error('No access token found');
        return;
      }

      const api = createAxiosInstance(accessToken);
      const response = await api.post('/api/client/applications', {
        name,
        address,
        userId,
        jobTitle,
        payment,
        duration,
        description,
        interested_users: [],
        questions: Object.keys(questions),
      });

      console.log('Application submitted:', response.data);
      
      setName('');
      setAddress('');
      setJobTitle('');
      setPayment('');
      setDuration('');
      setDescription('');
      setQuestions({});
    } catch (error) {
      console.error('Error submitting application:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAddField = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewFieldName('');
  };

  const handleConfirmAddField = () => {
    if (newFieldName) {
      setQuestions((prevQuestions) => ({
        ...prevQuestions,
        [newFieldName]: true,
      }));
    }
    handleCloseDialog();
  };

  const handleRemoveField = (fieldName) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = { ...prevQuestions };
      delete updatedQuestions[fieldName];
      return updatedQuestions;
    });
  };

  if (isLoading) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</Box>;
  }

  if (userRole === 'editor') {
    return <Navigate to="/editor-application" replace />;
  }

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {[
          { text: 'Dashboard', icon: <DashboardIcon />, route: '/dashboard' },
          { text: 'Jobs', icon: <WorkIcon />, route: '/jobs' },
          { text: 'Messages', icon: <MessageIcon />, route: '/messaging' },
          { text: 'Folders', icon: <FolderIcon />, route: '/folders' },
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={() => handleNavigation(item.route)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Client Application
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h5" gutterBottom component="div">
                Submit Application
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="name"
                      name="name"
                      label="Name"
                      fullWidth
                      variant="outlined"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="address"
                      name="address"
                      label="Address"
                      fullWidth
                      variant="outlined"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="jobTitle"
                      name="jobTitle"
                      label="Job Title"
                      fullWidth
                      variant="outlined"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="payment"
                      name="payment"
                      label="Payment"
                      fullWidth
                      variant="outlined"
                      value={payment}
                      onChange={(e) => setPayment(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="duration"
                      name="duration"
                      label="Duration"
                      fullWidth
                      variant="outlined"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="description"
                      name="description"
                      label="Description"
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Grid>
                  {Object.keys(questions).map((fieldName) => (
                    <Grid item xs={12} key={fieldName}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1">{fieldName}</Typography>
                        <Button onClick={() => handleRemoveField(fieldName)} color="secondary">
                          Remove
                        </Button>
                      </Box>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button onClick={handleAddField} variant="outlined">
                      Add Field
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                      Submit Application
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Container>
        </Box>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add New Field</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the name for the new field:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="fieldName"
            label="Field Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newFieldName}
            onChange={(e) => setNewFieldName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmAddField}>Add</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
);
}

export default ClientApplication;
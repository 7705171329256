import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

const items = [
  {
    title: 'Adaptable performance',
    videoId: '6g9_ZQXjPqo',
  },
  {
    title: 'Built to last',
    videoId: 's5r98UPhVko',
  },
  {
    title: 'Great user experience',
    videoId: '7YAz6PsNwf8',
  },
  {
    title: 'Innovative functionality',
    videoId: 'u5gg_kj3n5Y',
  },
  {
    title: 'Reliable support',
    videoId: 'Am1egniywO8',
  },
  {
    title: 'Precision in every detail',
    videoId: 'fw28rccPvFc',
  },
];

const VideoCard = ({ videoId, title }) => (
  <Card
    sx={{
      color: 'inherit',
      height: '21rem',
      width: '30rem',
      borderColor: 'hsla(220, 25%, 25%, 0.3)',
      backgroundColor: 'grey.800',
      overflow: 'hidden',
    }}
  >
    <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}?modestbranding=1`}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 0,
        }}
      />
    </Box>
    <Box sx={{ p: 2 }}>
      <Typography sx={{ color: 'white', textAlign: 'center', fontSize: '1.1rem' }}>
        {title}
      </Typography>
    </Box>
  </Card>
);

export default function Highlights() {
  return (
    <Box
      id="more-videos"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '70%' },
            textAlign: 'center',
            mb: 4,
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            More Videos
          </Typography>
        </Box>
        <Grid 
          container 
          spacing={3} 
          sx={{ 
            maxWidth: '1200px',
            mx: 'auto',
          }}
        >
          {items.map((item, index) => (
            <Grid xs={12} sm={6} key={index}>
              <VideoCard title={item.title} videoId={item.videoId} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
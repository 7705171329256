import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './Components/sign-up/SignUp'
import SignInSide from './Components/sign-in-side/SignInSide.js'
import MarketingPage from './Components/marketing-page/MarketingPage'
import Dashboard from './Components/Dashboard/Dashboard'
import ClientApplication from './Components/ClientApplication/ClientApplication'
import Jobs from './Components/JobApplying/Jobs'
import JobPage from './Components/JobApplying/JobPage'
import Messaging from './Components/Messaging/Messaging';
import MessageUser from './Components/Messaging/MessageUser';
import Folder from './Components/Folder/Folder';
import FolderItem from './Components/Folder/FolderItem';
import Admin from './Components/Admin/Admin';

function App() {
  return (
    <Router>
      <main className="main">
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<SignInSide />} />
          <Route path="/" element={<MarketingPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/job-post" element={<ClientApplication />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/job_application/:jobId" element={<JobPage />} />
          <Route path="/messaging" element={<Messaging />} />
          <Route path="/dm/:userId" element={<MessageUser />} />
          <Route path="/folders" element={<Folder />} />
          <Route path="/folder/:folderId" element={<FolderItem />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
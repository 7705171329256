import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Grid,
  Collapse,
  Button,
  Avatar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MessageIcon from '@mui/icons-material/Message';
import PeopleIcon from '@mui/icons-material/People';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import getMPTheme from './theme/getSignInSideTheme';
import { PaletteMode, ThemeProvider, createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkIcon from '@mui/icons-material/Work';
import FolderIcon from '@mui/icons-material/Folder';
import ApplicationDetailsDialog from './ApplicationDetailsDialog';

const API_BASE_URL = 'https://backend-summer-butterfly-3214.fly.dev';

const createAxiosInstance = (token = null) => {
  const config = {
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true
  };
  
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  return axios.create(config);
};

const drawerWidth = 240;

export default function Dashboard() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [applications, setApplications] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = React.useState('light');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const MPTheme = createTheme(getMPTheme(mode));
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const [expandedApp, setExpandedApp] = useState(null);
  const navigate = useNavigate();
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleNavigation = (route) => {
    navigate(route);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }

    const fetchUserInfo = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          setIsLoading(false);
          return;
        }

        const api = createAxiosInstance(accessToken);
        const response = await api.get('/api/user-info');
        
        setUserRole(response.data.role);
        setIsAuthenticated(true);
        fetchApplications(accessToken, response.data.role);
      } catch (error) {
        console.error('Error fetching user info:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const fetchApplications = async (accessToken, role) => {
    try {
      const endpoint = role === 'client' ? 'client/applications' : 'editor/applications';
      const api = createAxiosInstance(accessToken);
      const response = await api.get(`/api/${endpoint}`);
      
      if (Array.isArray(response.data)) {
        setApplications(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching applications:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleExpandApp = (appId) => {
    setExpandedApp(expandedApp === appId ? null : appId);
  };

  const handleUserClick = (userId) => {
    navigate(`/dm/${userId}`);
  };

  const handleApplicationClick = (application) => {
    setSelectedApplication(application);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Dashboard
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {[
          { text: 'Dashboard', icon: <DashboardIcon />, route: '/dashboard' },
          { text: 'Jobs', icon: <WorkIcon />, route: '/jobs' },
          { text: 'Messages', icon: <MessageIcon />, route: '/messaging' },
          { text: 'Folders', icon: <FolderIcon />, route: '/folders' },
        ].map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton onClick={() => handleNavigation(item.route)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {userRole === 'client' ? 'Client Dashboard' : 'Editor Dashboard'}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Typography variant="h4" gutterBottom>
            Open Jobs
          </Typography>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              {applications.length === 0 ? (
                <Typography>No applications found.</Typography>
              ) : (
                <List>
                  {applications.map((app) => (
                    <React.Fragment key={app._id}>
                      <ListItem>
                        <ListItemText 
                          primary={
                            <Typography
                              component="span"
                              onClick={() => handleApplicationClick(app)}
                              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                            >
                              {app.name}
                            </Typography>
                          }
                          secondary={
                            userRole === 'client' && (
                              <Button
                                size="small"
                                onClick={() => handleExpandApp(app._id)}
                                endIcon={expandedApp === app._id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              >
                                {app.interested_users?.length || 0} Interested Users
                              </Button>
                            )
                          }
                        />
                      </ListItem>
                      {userRole === 'client' && (
                        <Collapse in={expandedApp === app._id} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {app.interested_users?.map((user) => (
                              <ListItem key={user.id} button onClick={() => handleUserClick(user.id)} sx={{ pl: 4 }}>
                                <ListItemIcon>
                                  <Avatar>{user.username.charAt(0).toUpperCase()}</Avatar>
                                </ListItemIcon>
                                <ListItemText primary={user.username} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
      <ApplicationDetailsDialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
        application={selectedApplication}
      />
    </ThemeProvider>
  );
}
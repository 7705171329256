import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

const ApplicationDetailsDialog = ({ open, handleClose, application }) => {
  if (!application) return null;

  const excludedFields = ['_id', 'interested_users', 'userId'];

  const renderFieldValue = (key, value) => {
    if (key === 'questions') {
      return (
        <List>
          {Object.values(value).map((question, index) => (
            <ListItem key={index}>
              <ListItemText primary={`• ${question}`} />
            </ListItem>
          ))}
        </List>
      );
    }
    
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value, null, 2);
    }
    
    return value.toString();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{application.name}</DialogTitle>
      <DialogContent>
        <List>
          {Object.entries(application)
            .filter(([key]) => !excludedFields.includes(key))
            .map(([key, value]) => (
              <ListItem key={key}>
                <ListItemText
                  primary={key.charAt(0).toUpperCase() + key.slice(1)}
                  secondary={renderFieldValue(key, value)}
                />
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplicationDetailsDialog;